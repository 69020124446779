
const customPropeties = {
    a: '--brand-font-heading',
    b: '--brand-font-bodycopys'
}
export const fontConfig = {
    'abarth': {
        fonts: [
            {
                name: 'Abarth-Light',
                url: 'Abarth-Light.otf',
                weight: 'normal',
                format: 'opentype',
                var: customPropeties.b
            },
            {
                name: 'Abarth-Black',
                url: 'Abarth-Black.otf',
                weight: 'bold',
                format: 'opentype',
                var: customPropeties.a
            }
        ]
    },
    'alfa-romeo': {
        fonts: [
            {
                name: 'ApexNew-Light',
                url: 'ApexNew-Light.otf',
                weight: 'normal',
                format: 'opentype',
                var: customPropeties.b
            },
            {
                name: 'ApexNew-Bold',
                url: 'ApexNew-Bold.otf',
                weight: 'bold',
                format: 'opentype',
                var: customPropeties.a
            }
        ]
    },
    'bmw': {
        fonts: [
            {
                name: 'BMWTypeWebLightAll.20161018',
                url: 'BMWTypeWebLightAll.20161018.ttf',
                weight: 'normal',
                format: 'opentype',
                var: customPropeties.b
            },
            {
                name: 'BMWTypeWebBoldAll.20161018',
                url: 'BMWTypeWebBoldAll.20161018.ttf',
                weight: 'bold',
                format: 'opentype',
                var: customPropeties.a
            }
        ]
    },
    'citroen': {
        fonts: [
            {
                name: 'CitroenType-Regular',
                url: 'CitroenType-Regular.ttf',
                weight: 'normal',
                format: 'opentype',
                var: customPropeties.b
            },
            {
                name: 'CitroenType-Bold',
                url: 'CitroenType-Bold.ttf',
                weight: 'bold',
                format: 'opentype',
                var: customPropeties.a
            }
        ]
    },
    'dacia': {
        fonts: [
            {
                name: 'DaciaBlock-Regular',
                url: 'DaciaBlock-Regular.woff',
                weight: 'normal',
                format: 'opentype',
                var: customPropeties.b
            },
            {
                name: 'DaciaBlock-Bold',
                url: 'DaciaBlock-Bold.woff',
                weight: 'bold',
                format: 'opentype',
                var: customPropeties.a
            }
        ]
    },
    'ds': {
        fonts: [
            {
                name: 'DSAutomobilesPro-Regular',
                url: 'DSAutomobilesPro-Regular.ttf',
                weight: 'normal',
                format: 'opentype',
                var: customPropeties.b
            },
            {
                name: 'DSAutomobilesPro-Bold',
                url: 'DSAutomobilesPro-Bold.ttf',
                weight: 'bold',
                format: 'opentype',
                var: customPropeties.a
            }
        ]
    },
    'fiat': {
        fonts: [
            {
                name: 'Poppins-Regular',
                url: 'Poppins-Regular.ttf',
                weight: 'normal',
                format: 'opentype',
                var: customPropeties.b
            },
            {
                name: 'Poppins-Bold',
                url: 'Poppins-Bold.ttf',
                weight: 'bold',
                format: 'opentype',
                var: customPropeties.a
            }
        ]
    },
    'ford': {
        fonts: [
            {
                name: 'FordAntennaCond-Regular',
                url: 'FordAntennaCond-Regular.ttf',
                weight: 'normal',
                format: 'opentype',
                var: customPropeties.b
            },
            {
                name: 'FordAntennaCond-Bold',
                url: 'FordAntennaCond-Bold.ttf',
                weight: 'bold',
                format: 'opentype',
                var: customPropeties.a
            }
        ]
    },
    'hyundai': {
        fonts: [
            {
                name: 'HyundaiSansHead-Regular',
                url: 'HyundaiSansHead-Regular.ttf',
                weight: 'normal',
                format: 'opentype',
                var: customPropeties.b
            },
            {
                name: 'HyundaiSansText-Bold',
                url: 'HyundaiSansText-Bold.ttf',
                weight: 'bold',
                format: 'opentype',
                var: customPropeties.a
            }
        ]
    },
    'jaguar': {
        fonts: [
            {
                name: 'ProximaNova-Regular',
                url: 'ProximaNova-Regular.woff',
                weight: 'normal',
                format: 'opentype',
                var: customPropeties.b
            },
            {
                name: 'JaguarWeb-Bold',
                url: 'JaguarWeb-Bold.ttf',
                weight: 'bold',
                format: 'opentype',
                var: customPropeties.a
            }
        ]
    },
    'jeep': {
        fonts: [
            {
                name: 'Roboto-Regular',
                url: 'Roboto-Regular.ttf',
                weight: 'normal',
                format: 'opentype',
                var: customPropeties.b
            },
            {
                name: 'Roboto-Bold',
                url: 'Roboto-Bold.ttf',
                weight: 'bold',
                format: 'opentype',
                var: customPropeties.a
            }
        ]
    },
    'kia': {
        fonts: [
            {
                name: 'KIAM',
                url: 'KIAM.ttf',
                weight: 'normal',
                format: 'opentype',
                var: customPropeties.b
            },
            {
                name: 'KIAM',
                url: 'KIAM.ttf',
                weight: 'bold',
                format: 'opentype',
                var: customPropeties.a
            }
        ]
    },
    'land-rover': {
        fonts: [
            {
                name: 'Avenir_Roman',
                url: 'Avenir_Roman.ttf',
                weight: 'normal',
                format: 'opentype',
                var: customPropeties.b
            },
            {
                name: 'LandRoverWeb-Bold',
                url: 'LandRoverWeb-Bold.ttf',
                weight: 'bold',
                format: 'opentype',
                var: customPropeties.a
            }
        ]
    },
    'mazda': {
        fonts: [
            {
                name: 'MazdaType-Regular',
                url: 'MazdaType-Regular.otf',
                weight: 'normal',
                format: 'opentype',
                var: customPropeties.b
            },
            {
                name: 'MazdaType-Bold',
                url: 'MazdaType-Bold.otf',
                weight: 'bold',
                format: 'opentype',
                var: customPropeties.a
            }
        ]
    },
    'mg': {
        fonts: [
            {
                name: 'FavoritStd-Regular',
                url: 'FavoritStd-Regular.woff',
                weight: 'normal',
                format: 'opentype',
                var: customPropeties.b
            },
            {
                name: 'FavoritStd-Bold',
                url: 'FavoritStd-Bold.woff',
                weight: 'bold',
                format: 'opentype',
                var: customPropeties.a
            }
        ]
    },
    'mini': {
        fonts: [
            {
                name: 'MINISerif-Regular',
                url: 'MINISerif-Regular.otf',
                weight: 'normal',
                format: 'opentype',
                var: customPropeties.b
            },
            {
                name: 'MINISerif-Bold',
                url: 'MINISerif-Bold.otf',
                weight: 'bold',
                format: 'opentype',
                var: customPropeties.a
            }
        ]
    },
    'nissan': {
        fonts: [
            {
                name: 'NissanBrand-Regular',
                url: 'NissanBrand-Regular.ttf',
                weight: 'normal',
                format: 'opentype',
                var: customPropeties.b
            },
            {
                name: 'NissanBrand-Bold',
                url: 'NissanBrand-Bold.ttf',
                weight: 'bold',
                format: 'opentype',
                var: customPropeties.a
            }
        ]
    },
    'opel': {
        fonts: [
            {
                name: 'OpelSans-Regular',
                url: 'OpelSans-Regular.woff',
                weight: 'normal',
                format: 'woff',
                var: customPropeties.b
            },
            {
                name: 'OpelSans-Bold',
                url: 'OpelSans-Bold.woff',
                weight: 'bold',
                format: 'woff',
                var: customPropeties.a
            }
        ]
    },
    'peugeot': {
        fonts: [
            {
                name: 'PeugeotNewRegular',
                url: 'PeugeotNewRegular.otf',
                weight: 'normal',
                format: 'opentype',
                var: customPropeties.b
            },
            {
                name: 'PeugeotNewBold',
                url: 'PeugeotNewBold.otf',
                weight: 'bold',
                format: 'opentype',
                var: customPropeties.a
            }
        ]
    },
    'renault': {
        fonts: [
            {
                name: 'Nouvel-R',
                url: 'Nouvel-R.otf',
                weight: 'normal',
                format: 'opentype',
                var: customPropeties.b
            },
            {
                name: 'Nouvel-R-Bold',
                url: 'Nouvel-R-Bold.otf',
                weight: 'bold',
                format: 'opentype',
                var: customPropeties.a
            }
        ]
    },
    'marcos-cycles': {
        fonts: [
            {
                name: 'Abarth-Light',
                url: 'Abarth-Light.otf',
                weight: 'normal',
                format: 'opentype',
                var: customPropeties.b
            },
            {
                name: 'Abarth-Black',
                url: 'Abarth-Black.otf',
                weight: 'bold',
                format: 'opentype',
                var: customPropeties.a
            }
        ]
    },
    'byd': {
        fonts: [
            {
                name: 'Montserrat',
                url: 'Montserrat-Regular.ttf',
                weight: 'normal',
                format: 'opentype',
                var: customPropeties.b
            },
            {
                name: 'Montserrat',
                url: 'Montserrat-Bold.ttf',
                weight: 'bold',
                format: 'opentype',
                var: customPropeties.a
            }
        ]
    },
    'leapmotor': {
        fonts: [
            {
                name: 'Montserrat',
                url: 'Montserrat-Regular.ttf',
                weight: 'normal',
                format: 'opentype',
                var: customPropeties.b
            },
            {
                name: 'Montserrat',
                url: 'Montserrat-Bold.ttf',
                weight: 'bold',
                format: 'opentype',
                var: customPropeties.a
            }
        ]
    }
};