import {fontConfig} from './font.config'

export default function useFonts(){
    const route = useRoute()

    function setFont(){
        const brand = route.params.marca
        const fontSelected = fontConfig[brand]
        const root = document.documentElement;

        if(brand && Object.keys(fontSelected).length){
            let bodycopy = '';
            let headline = '';

            const styleExist = document.getElementById('customFontFace')

            if(styleExist){
                styleExist.remove()
            }

            
            const newStyle = document.createElement('style');
            newStyle.setAttribute('id', 'customFontFace')
            const appBrand = document.querySelector('.app-brand')

            fontSelected.fonts.forEach(item => {
                const url = `/fonts/brand/${brand}/${item.url}`;
                const fontName = item.name

                newStyle.appendChild(document.createTextNode("\
                @font-face {\
                    font-family: " + fontName + ";\
                    src: url('" + url + "') format('" + item.format + "');\
                }\
                "));

                if(item.var === '--brand-font-heading'){
                    headline = fontName
                }
                else {
                    bodycopy = fontName
                }

                if(appBrand){
                    appBrand.style.fontFamily=bodycopy;
                }
            });

        
            document.head.appendChild(newStyle);
        
            root.style.setProperty('--brand-font-heading', headline);
            root.style.setProperty('--brand-font--bodycopys', bodycopy);
            root.style.setProperty('--font-primary', bodycopy);
            root.style.setProperty('--font-primary-bold', headline);
        }
        else{
            
            root.style.setProperty('--font-primary', 'helvetica_neue_lt_pro55_roman');
            root.style.setProperty('--font-primary-bold', 'helvetica_neue_lt_pro75_bold');

            const styleExist = document.getElementById('customFontFace')

            if(styleExist){
                styleExist.remove()
            }

        }
        
    }
    

    return {
        setFont
    }

}